:local(.frame) {
  background: var(--background);
  width: 100%;
  height: auto;
  min-height: 100%;
  composes: space-col from global;
  flex: 1;
}

:local(.frame_user) {
  composes: space-col from global;
  composes: small from global;
  composes: inside from global;
  align-items: flex-end;
}
