:local(.boundary) {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

:local(.boundary_icon) {
  font-size: 33px;
  margin-bottom: 20px;
}

:local(.boundary_title) {
  font-size: 22px;
  margin-bottom: 6px;
}

:local(.boundary_message) {
  margin-bottom: 20px;
  max-width: 50%;
  text-align: center;
}

:local(.boundary_action) {
  font-size: 16px;
  padding: 0 10px;
}
